<template>
  <el-dialog
    title="现售预订"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="供应商" prop="orgId">
          <el-select v-model="dataForm.orgId" :disabled="disabled">
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="公司" prop="companyId">
          <el-select v-model="dataForm.companyId" :disabled="disabled">
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="时段" prop="periodId">
          <el-select v-model="dataForm.periodId" :disabled="disabled">
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="星期" prop="weeks" class="fullList">
          <el-checkbox-group v-model="dataForm.weeks" size="medium">
            <el-checkbox-button
              v-for="(item, index) in weeksList"
              :key="index"
              :label="item.id"
              >{{ item.week }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="日期" prop="startDate">
          <el-date-picker
            v-model="pickupDate"
            @change="changeDate()"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时间" prop="time" class="inlineTime">
          <el-time-picker
            v-model="dataForm.advanceStartTime"
            value-format="HH:mm"
            format="HH:mm"
            :picker-options="{
              selectableRange: `00:00:00 - ${dataForm.limitTime}`,
              format: 'HH:mm',
            }"
            placeholder="开始时间"
          >
          </el-time-picker>
          <el-time-picker
            v-model="dataForm.advanceEndTime"
            value-format="HH:mm"
            format="HH:mm"
            :picker-options="{
              selectableRange: `00:00:00 - ${dataForm.limitTime}`,
              format: 'HH:mm',
            }"
            placeholder="结束时间"
          >
          </el-time-picker>
        </el-form-item>

        <div class="tablePart">
          <el-button
            type="primary"
            v-if="pickupDate >= now"
            @click="addDataList()"
            class="el-icon-add-location"
            >新增</el-button
          >
        </div>

        <div class="form-table">
          <div class="table-header">
            <div class="table-header-item">商品名称</div>
            <div class="table-header-item headWidth150">数量</div>
            <div class="table-header-item headWidth150">操作</div>
          </div>
          <div class="table-body">
            <div
              v-for="advanceSale in dataList"
              :key="advanceSale.orgFreezerId"
              class="table-body-wrap"
            >
              <div class="table-body-item">
                <el-select v-model="advanceSale.goodsId">
                  <el-option
                    v-for="(item, index) in goodsList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div class="table-body-item headWidth150">
                <el-input v-model="advanceSale.quantity"></el-input>
              </div>
              <div class="table-body-item headWidth150">
                <i
                  @click="handleDeleteItem(advanceSale)"
                  class="el-icon-delete"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        v-if="pickupDate >= now"
        >确定</el-button
      >
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      now: new Date(new Date().getTime())
        .toJSON()
        .split('T')
        .join(' ')
        .substr(0, 10),
      pickupDate: new Date(new Date().getTime())
        .toJSON()
        .split('T')
        .join(' ')
        .substr(0, 10),
      // DisApplyTimeHours:[`00:00:00 - 11:10:10`], // 可选时间段  ！！注意中间空格
      dataList: [
        {
          id: '',
          goodsId: '',
          quantity: '',
        },
      ],
      dataForm: {
        id: '',
        advanceStartTime: '',
        advanceEndTime: '',
        weeks: [1, 2, 3, 4, 5, 6, 7],
        limitTime: '',
        orgId: '',
        companyId: '',
        isUnified: true,
        startDate: '',
        endDate: '',
      },
      time: [new Date(), new Date()],
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
      orgList: [],
      companyList: [],
      periodList: [],
      goodsList: [],
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getOrgList();
    this.getCompanyList();
    this.getPeriodList();
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.pickupDate = new Date(new Date().getTime())
        .toJSON()
        .split('T')
        .join(' ')
        .substr(0, 10);
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/orgcompany/advanceSale/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.tcOrgCompany };
              this.dataForm.weeks = data.tcOrgCompany.weeks
                ? data.tcOrgCompany.weeks
                    .split(',')
                    .map((item) => parseInt(item))
                : [];
              this.getGoodsList();
              this.getDataList();
            }
          });
        }
      });
    },
    getDataList() {
      if (this.dataForm.id) {
        this.$http({
          url: `/tc/advancesale/queryAll`,
          params: {
            id: this.dataForm.id,
            pickupDate: this.pickupDate,
          },
          method: 'get',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.dataList = data.list;
          }
        });
      }
    },
    changeDate() {
      this.getDataList();
    },
    addDataList() {
      this.dataList.push({
        goodsId: '',
        orgCompanyId: '',
        quantity: '',
      });
    },
    handleDeleteItem(item) {
      if (this.dataList.length === 1)
        return this.$error('至少需要填写一条记录');
      let goods = this.goodsList.filter((i) => i.id === item.goodsId);
      this.$warning(`是否确认删除${goods[0].name}?`).then(() => {
        this.dataList = this.dataList.filter((i) => i.goodsId !== item.goodsId);
      });
    },
    getOrgList() {
      this.$http({
        url: `/cc/org/all`,
        method: 'get',
        params: { type: 0, isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },

    getCompanyList() {
      this.$http({
        url: `/tc/company/combo`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    getGoodsList() {
      this.$http({
        url: `/cc/goods/goodsCombo/${this.dataForm.orgId}`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.goodsList = data.goodsList;
        }
      });
    },

    getPeriodList() {
      this.$http({
        url: `/cc/global-period/all`,
        method: 'get',
        params: { isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let goodsList = this.dataList.map((item) => {
            return { goodsId: item.goodsId, quantity: item.quantity };
          });
          this.$http({
            url: `/tc/advancesale/save`,
            method: 'post',
            data: {
              id: this.dataForm.id,
              pickupDate: this.pickupDate,
              advanceStartTime: this.dataForm.advanceStartTime,
              advanceEndTime: this.dataForm.advanceEndTime,
              advanceSaleList: goodsList,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.status === 422) {
              this.$message({
                message: data.errors.space,
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.checkBtnColor {
  label.is-checked {
    .el-checkbox-button__inner {
      background-color: #dcdcdc;
    }
  }
}
.inlineTime {
  .el-form-item__content {
    .el-date-editor {
      width: 49.5% !important;
    }
  }
}
</style>
